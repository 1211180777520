// Styled Components
import { WelcomeToTheJungleIconWrapper } from './WelcomeToTheJungleIcon.styles'

const WelcomeToTheJungleIcon = () => {
  return (
    <WelcomeToTheJungleIconWrapper
      xmlns='http://www.w3.org/2000/svg'
      width='200'
      height='200'
      fill='none'
      viewBox='0 0 200 200'
    >
      <g clipPath='url(#clip0_100_2)'>
        <path
          fill='#D90017'
          d='M99.95 0C44.84 0 0 44.84 0 99.95s44.84 99.95 99.95 99.95 99.95-44.84 99.95-99.95S155.06 0 99.95 0m0 8.59c7.7 0 15.21.99 22.42 2.77-2.76 23.8-10.47 50.67-22.42 50.67S80.3 35.17 77.53 11.36c7.11-1.88 14.62-2.77 22.42-2.77m-39.8 8.99c5.63 22.91 23.01 49.98 39.7 50.07 16.79 0 34.07-27.06 39.7-50.07 4.54 2.17 8.89 4.74 13.04 7.7-8.79 20.74-28.25 50.07-52.74 50.77-24.4-.69-43.95-30.02-52.74-50.77 4.15-2.86 8.49-5.43 13.04-7.7m39.8 173.83c-7.7 0-15.21-.99-22.42-2.76 2.77-23.8 10.47-50.67 22.42-50.67s19.65 26.86 22.42 50.67c-7.21 1.88-14.72 2.76-22.42 2.76m39.7-9.09c-5.63-22.91-23.01-49.98-39.7-50.07-16.79 0-34.07 27.06-39.7 50.07a87 87 0 0 1-13.04-7.7c8.79-20.74 28.25-50.07 52.74-50.77 24.4.69 43.95 30.03 52.74 50.77a86.7 86.7 0 0 1-13.04 7.7m27.36-20.25c-12.25-18.27-38.72-43.85-67.06-43.85s-54.81 25.58-67.06 43.85a96 96 0 0 1-9.19-11.65c12.94-16.69 40.1-40.69 76.25-40.69s63.31 24 76.25 40.69c-2.77 4.15-5.83 8-9.19 11.65m24.3-62.12c0 11.56-2.17 22.62-6.12 32.79-20.64-17.18-52.35-28.54-85.33-28.54s-64.69 11.36-85.33 28.64c-3.95-10.17-6.12-21.23-6.12-32.79s2.17-22.62 6.12-32.79C35.17 84.45 66.88 95.9 99.86 95.9s64.69-11.36 85.33-28.54c3.95 9.98 6.12 21.04 6.12 32.59m-91.36-9.78c-36.05 0-63.31-24-76.25-40.69 2.77-4.15 5.83-8 9.19-11.65C45.14 56.1 71.61 81.68 99.95 81.68s54.81-25.58 67.06-43.85c3.36 3.65 6.42 7.51 9.09 11.65-12.94 16.69-40.1 40.69-76.15 40.69'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_100_2'>
          <path fill='#fff' d='M0 0h199.9v199.9H0z'></path>
        </clipPath>
      </defs>
    </WelcomeToTheJungleIconWrapper>
  )
}
export default WelcomeToTheJungleIcon
